.card-layout {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background: white;
  border-radius: var(--card-radius);
  box-shadow: var(--card-shadow);

  & .card-title {
    flex: none;
  }

  & .card-content {
    flex: auto;
    box-sizing: border-box;
  }

  &.variant-pc {
    & > .card-title {
      padding: var(--card-padding);
      padding-bottom: 0;

      & .title-text {
        font-size: 18px;
        font-weight: 600;
      }

      & > .title-desc {
        margin-top: 4px;
        color: #686868;
      }
    }

    & > .card-content {
      padding: var(--card-padding);
    }
  }

  &.variant-mobile {
    box-shadow: 0 0 4px 4px rgb(234 234 234 / 25%);

    & > .card-title {
      padding: 8px 12px;
      border-bottom: 1px solid var(--border-color-light);

      & .title-text {
        color: #8c8c8c;
      }
    }

    & > .card-content {
      padding: 20px;
    }
  }
}

.grid-layout {
  display: flex;
  flex-flow: row wrap;
  margin: calc(var(--row-gap) / -1) 0 0 calc(var(--column-gap) / -1);

  & > * {
    box-sizing: border-box;
    min-width: 0;
    flex-basis: auto;
    flex-grow: 0;
  }
}

.grid-layout-item {
  padding: var(--row-gap) 0 0 var(--column-gap);
}

.grid-layout-item-span {
  width: calc(100% * var(--span) / var(--column-number));
}

.stack-layout {
  display: flex;
  flex-direction: row;
  gap: 8px;

  & > * {
    /* 默认压缩子元素，eg：防止被 input 的 100% 撑开 */
    min-width: 0;
  }
}

