.icp-table-toolbar {
  flex: none;
  margin-bottom: 8px;
  -webkit-font-smoothing: initial;

  & hr {
    display: inline-block;
    width: 1px;
    height: 16px;
    margin: 0 4px;
    border: 0;
    background: var(--border-color);
  }

  & .input-element {
    width: auto;
  }
}

.icp-table-toolbar-main {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 8px;
  min-height: 32px;

  & > div {
    flex: auto;
  }

  & button.ant-btn-icon-only {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    &:not(.ant-btn-dangerous):not(:disabled) {
      color: #5f6368;
    }
  }
}

.icp-table-title {
  font-size: 16px;
  font-weight: 500;
}

.icp-table-actions {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 8px;

  & > .icp-search {
    width: 200px;
  }
}

.icp-table-menu {
  min-width: 180px;
  padding: 4px;

  & > .icp-action-button {
    width: 100%;
    height: 28px;
    justify-content: flex-start;
  }

  & > .item {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    gap: 6px;
    height: 28px;
    padding: 2px 6px;
    border-radius: 4px;
    user-select: none;
    cursor: pointer;
  }
}

.icp-table-column-select {
  display: flex;
  flex-direction: column;

  & .icp-search {
    flex: none;
    margin: 6px 6px 2px;
  }

  & .icp-table-menu {
    flex: auto;
    overflow: auto;
  }
}

.icp-table-fullscreen .icp-table-column-select {
  max-height: calc(100% - 68px);
  top: 60px;
}

.icp-table-setting-hidden-wrapper {
  position: absolute;
  right: 1px;
  top: 40px;
  height: calc(100% - 40px);
  border-top-right-radius: calc(var(--ag-border-radius) * 2);
  border-bottom-right-radius: calc(var(--ag-border-radius) * 2);
  overflow: hidden;
  z-index: var(--max-z-index);
}

.icp-table-setting {
  box-sizing: border-box;
  width: 256px;
  height: 100%;
  margin-right: -1px; /* -1px to avoid border repeat with ag-grid */
  padding: 5px;
  border: 1px solid var(--ag-border-color);
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #f8f8f8;
  font-size: var(--font-size);
  overflow: auto;
  transition: all ease 0.15s;

  & h2 {
    padding: 0;
    margin: 12px 12px 24px;
    font-size: 18px;
    font-weight: 600;
  }
}

.icp-table-fullscreen .icp-table-setting-hidden-wrapper {
  height: calc(100% - 80px);
  right: 21px;
  top: 60px;
}

.icp-table-setting-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 20px 12px;
}

.icp-table-setting-column {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 20px 12px;
}

.icp-table-setting-actions {
  margin: 12px 0;
}

.icp-table-setting-action {
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  padding: 4px 12px;
  min-height: 32px;
  user-select: none;
  cursor: pointer;

  &:hover {
    border-radius: var(--border-radius);
    background: rgb(0 0 0 / 5%);
  }
}

.icp-table-dirty-dot {
  position: absolute;
  right: 1px;
  top: 1px;
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #ff8c00;
}

.icp-table-save-view {
  & > hr {
    width: 100%;
    height: 1px;
    margin: 4px 0;
    border: 0;
    background: var(--border-color);
  }

  & .item {
    justify-content: space-between;

    & > button {
      width: 20px;
      min-width: 20px;
      height: 20px;
      border-radius: 2px;
      opacity: 0;
    }

    &:hover button {
      opacity: 1;
    }
  }
}
