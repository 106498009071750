.icp-menu-list {
  padding: 4px;

  &.submenu-locked .icp-menu-item:hover:not(:active) {
    background: none;
  }

  &.has-selected {
    & .icp-menu-item {
      position: relative;
      padding-left: 22px;
    }
  }

  & .keyboard-shortcut-spaced {
    letter-spacing: 0.08em;
  }

  & hr {
    margin: 4px;
    border: 0;
    border-bottom: 1px solid var(--border-color);
  }
}

.icp-menu-item {
  gap: 8px;
  line-height: 22px;
  padding: 2px 6px;
  border-radius: 4px;
  user-select: none;

  &.icp-danger {
    color: var(--error-color);
  }
}

.icp-menu-item-selected-icon {
  position: absolute;
  left: 4px;
}

.icp-menu-label {
  flex: 1 1 auto;
}
