.icp-reorderable {
  box-sizing: border-box;
  transition: left 0.2s, top 0.2s;
}

#icp-drag-ghost {
  position: absolute;
  margin: 0;
  z-index: 10;
  pointer-events: none;
  transition: none;
}
