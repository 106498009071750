.gantt-element {
  /* stylelint-disable-next-line selector-class-pattern */
  & .buttonBg {
    background: #fff;
  }


  & .gantt_grid_data .gantt_row.odd:hover,
  & .gantt_grid_data .gantt_row:hover {
    background-color: #e3eaf8;
  }

  & .gantt_tree_icon,
  & .gantt_tree_indent {
    flex-grow: 0;
    flex-shrink: 0;
  }

  & .gantt_tree_indent {
    width: 15px;
    height: 100%;
  }

  & .gantt_tree_content,
  & .gantt_tree_icon {
    vertical-align: top;
  }

  & .gantt_tree_icon {
    width: 10px;
    height: 100%;
    background-repeat: no-repeat;
    background-position: 50%;
  }

  & .gantt_tree_icon.gantt_open {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAACXBIWXMAAAuUAAALlAF37bb0AAAAIGNIUk0AAHolAACAgwAA+f8AAIDoAABSCAABFVgAADqXAAAXb9daH5AAAAH/SURBVHgBAO8BEP4B////AAAAAAB1dXWV2NjYa+7u7gAJCQkA////AAAAAAAAAAAAvb29AAAAAAACAAAAAAAAAAAGBgZqKysrvREREQD7+/sACAgIAAgICAAICAgAAAAAAAAAAAACAAAAAAAAAAD6+vr1BAQEQisrK8YQEBAA9vb2AP39/QD9/f0AAAAAAAAAAAACAAAAAAAAAAABAQEA+fn5AAUFBTkqKirWCAgIBPLy8gD5+fkAAAAAAAAAAAACAAAAAAAAAAAAAAAAAQEBAPn5+QADAwMpMjIy3xkZGRsEBAQAAAAAAAAAAAACAAAAAAAAAAAAAAAAAAAAAAEBAQD4+PgABQUFHCQkJKoVFRUwAAAAAAAAAAAEAAAAAAAAAAAAAAAAAAAAAP///wAICAgA+/v76N3d3Vvx8fHgAAAAAAAAAAAEAAAAAAAAAAAAAAAA////AAcHBwD9/f3b0dHRLfDw8PkGBgYAAAAAAAAAAAAEAAAAAAAAAAD///8ABwcHAPv7+8vY2Ng29/f3AA4ODgAAAAAAAAAAAAAAAAACAAAAAAAAAAAGBgYL+/v7w9LS0jbu7u4ACAgIAAAAAAAAAAAAAAAAAAAAAAACAAAAAAAAAAD4+Pia2traPvj4+AAMDAwA////AAAAAAAAAAAAAAAAAAAAAAABAAD//z9HdQMRW/QpAAAAAElFTkSuQmCC');
    width: 18px;
    cursor: pointer;
  }

  & .gantt_tree_icon.gantt_close {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAsAAAALCAYAAACprHcmAAAACXBIWXMAAAuUAAALlAF37bb0AAAAIGNIUk0AAHolAACAgwAA+f8AAIDoAABSCAABFVgAADqXAAAXb9daH5AAAAH/SURBVHgBAO8BEP4B////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABcnJymQgICGb6+vr1AQEBAAAAAAAAAAAAAAAAAAAAAAD///8ABgYGC/r6+pYDFxcXtBEREUMGBgYk/Pz8BgEBAQYAAAAGAAAABv///wYHBwcG/f39vtfX11cC8PDwANLS0j77+/vLBwcHAP///wAAAAAA////AAcHBwD7+/vH1NTUQ+7u7gACCAgIAPT09ADY2Ng2/f392wcHBwD+/v4ABwcHAP39/dfY2Ng68/PzAAkJCQAC////AAwMDADy8vIA0dHRLf///+gMDAwA////5NDQ0C7y8vIADAwMAP///wACAAAAAP///wAHBwcA8PDw+dnZ2Tn39/fG2NjYOPHx8fwHBwcAAAAAAAAAAAADJCQkAAAAAAAAAAAA////APv7+/D8/PzO7+/v2wICAgAEBAQAAgICAPz8/AAB////AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAABAAD//4RfjvnDJoM6AAAAAElFTkSuQmCC');
    width: 18px;
    cursor: pointer;
  }

  & .gantt_tree_icon.gantt_blank {
    width: 18px;
  }

  & .gantt_tree_icon.gantt_file,
  & .gantt_tree_icon.gantt_folder_closed,
  & .gantt_tree_icon.gantt_folder_open {
    background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAC0lEQVQYV2NgAAIAAAUAAarVyFEAAAAASUVORK5CYII=');
  }

  & .gantt_grid_data .gantt_cell {
    border-right: none;
    border-left: none;
    color: rgb(0 0 0 / 80%);
    font-weight: 400;
  }

  & .gantt_row,
  & .gantt_task_row {
    border-bottom: 0;
    background-color: #fff;
  }

  & .gantt_task_line.gantt_milestone .gantt_task_content {
    transform: initial;
    border: 4px solid rgb(186 231 255 / 100%);
    outline: 4px solid rgb(230 247 255 / 100%);
  }

  & .gantt_task_cell.week_end {
    background-color: #eff5fd;
  }

  & .gantt_grid_data .gantt_row.gantt_selected,
  & .gantt_grid_data .gantt_row.odd.gantt_selected,
  & .gantt_task_row.gantt_selected {
    background-color: #e3eaf8;
  }

  & .gantt_task_row.gantt_selected .gantt_task_cell {
    border-right-color: #e3eaf8;
  }

  & .gantt_layout_cell_border_left {
    border-left: 1px solid #e3eaf8;
  }

  & .gantt_layout_cell_border_right {
    border-right: 1px solid #e3eaf8;
  }

  & .gantt_layout_cell_border_top {
    border-top: 1px solid #e3eaf8;
  }

  & .gantt_layout_cell_border_bottom {
    border-bottom: 1px solid #e3eaf8;
  }

  & .gantt_grid_scale,
  & .gantt_task_scale {
    border: 1px solid #e3eaf8;
    border-top: none;
    border-left: none;
    border-right: none;
  }

  & .gantt_task_cell {
    border-right-color: rgb(227 234 248 / 50%);
  }

  & .gantt_tree_content {
    height: 100%;
    white-space: nowrap;
    min-width: 0;
    color: #333;
  }

  & [data-column-name='start_date'] .gantt_tree_content {
    color: rgba(0 0 0 / 45%);
  }

  & .today_line {
    background-color: #1890ff;
  }

  & .gantt_task_link.finish_to_start .gantt_line_wrapper div {
    background-color: #8d8d8d;
  }

  & .gantt_task_link.finish_to_start:hover .gantt_line_wrapper div {
    box-shadow: 0 0 5px 0 #8d8d8d;
  }

  & .gantt_task_link.finish_to_start .gantt_link_arrow_right {
    border-left-color: #8d8d8d;
  }

  & .gantt_task_line {
    background: #1890ff;
    border-radius: 20px;
    border: none;
  }

  & .gantt_task_line.gantt_project {
    background: rgb(230 247 255 / 100%);
    border: none;
    height: 2px;
  }

  & .gantt_task_line.gantt_project:hover {
    background: #bae7ff;
  }

  & .gantt_project .gantt_task_content {
    color: #1890ff;
  }

  & .gantt_task_content {
    font-size: 13px;
    line-height: 23px;
  }

  & .gantt_task_line:hover {
    background: #096dd9;
  }

  & .gantt_task_line.gantt_milestone {
    background: #1890ff;
  }

  & .bar-red {
    background-color: #ff4d4f;
  }

  & .bar-red:hover {
    background-color: #f5222d;
  }

  & .bar-green {
    background-color: #52c41a;
  }

  & .bar-green:hover {
    background-color: #38910d;
  }
}

