.ant-picker.ant-picker {
  width: 100%;
}

.ant-tooltip.ant-tooltip {
  font-size: 12px;
}

.ant-tabs.ant-tabs {
  & .ant-tabs-nav {
    &::before {
      border-bottom: 0;
    }
  }
}

.ant-tree-select-dropdown .ant-select-tree .ant-select-tree-checkbox {
  margin-block-start: 2px;
}

/* antd 的 button 只对自己的 ant icon 设置了 margin，其余的 icon 会贴着，还可能会不居中 */
button.ant-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  /* & svg + span {
    margin-left: 8px;
  } */
}

/* cover antd default collapse style */
.ant-collapse:not(.ant-collapse-ghost) {
  border: 0;
  background: white;

  & .ant-collapse-item {
    border-color: rgb(0 0 0 / 7%);
  }

  & .ant-collapse-item:last-child {
    border-bottom: 0;
  }

  & .ant-collapse-header {
    background: transparent;
  }

  & .ant-collapse-content {
    border-color: rgb(0 0 0 / 7%);
  }
}

/* antd modal 默认没有滚动条, 这里实现 ant-modal-content 出现滚动，header 和 footer 固定 */
.ant-modal-wrap.ant-modal-centered {
  & .ant-modal-content {
    display: flex;
    flex-direction: column;
    max-height: calc(100vh - 32px);
    padding-left: 0;
    padding-right: 0;
    overflow: auto;

    & .ant-modal-header,
    & .ant-modal-footer {
      flex: none;
      padding: 0 24px;
    }

    & .ant-modal-body {
      flex: auto;
      overflow: auto;
      padding: 0 24px;
      word-wrap: normal; /* antd 是 break-word 会让 monaco 的 line number 换行，例如 15 显示成 2 行 1 和 5 */
    }
  }

  & .fullscreen {
    margin: 0;
    width: 100% !important;
    max-width: 100vw;
    max-height: 100vh;
    top: 0;
    padding: 0;

    & .ant-modal-content {
      height: 100vh;
      max-height: 100vh;
      padding: 0;
      border-radius: 0;
    }

    & .ant-modal-body {
      padding: 0;
    }
  }
}
