.pdf-chat-ai {
  height: 100%;
  background-color: #f0f5ff;

  & .ai-agent {
    max-width: 1440px;

    & .ai-agent-input-actions {
      margin-right: 8px;
    }
  }
}

.pdf-chat-source-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  & .pdf-chat-source-prefix {
    color: #b5b5c3;
    font-size: 12px;
    margin-right: 2px;
  }

  & .pdf-chat-source-button {
    border: none;
    background: none;
    width: 20px;
    height: 20px;
  }
}

.pdf-chat-source-modal {
  & .ant-modal {
    top: 50px;
  }

  & .ant-modal-content {
    padding: 20px;
  }

  & .ant-modal-body {
    height: calc(100vh - 200px);
    overflow: auto;

    &::-webkit-scrollbar {
      height: 12px;
      width: 12px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgb(100 100 100 / 40%);
      background-clip: content-box;
      border-radius: 10px;
      border: 3px solid transparent;
    }

    &::-webkit-scrollbar-thumb:hover {
      background-color: rgb(100 100 100 / 70%);
    }
  }
}

.pdf-chat-feedback-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;

  & .pdf-chat-feedback-form-message {
    color: #7b8495;
    font-size: 12px;
  }
}

#root.pdf-chat-embedded {
  height: 100vh;

  & .template-wrapper {
    padding: 0;
    background-color: #f0f5ff;

    & .template-content {
      padding: 0;
      overflow-y: hidden;
    }
  }
}

@media screen and (max-width: 512px) {
  #root.pdf-chat-embedded {
    & .template-wrapper {
      margin-left: 0;

      & .template-sidebar {
        display: none;
      }
    }
  }
}
