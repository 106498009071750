.form-renderer {
  height: 100%; /* TODO 先加上看项目使用情况，没 bug 去掉注释 */

  &.fetching {
    position: relative;
  }
}

.form-renderer-block-loading {
  height: 100%;
  min-height: 200px;
}

/* .form-renderer-data-loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgb(255 255 255 / 25%);
} */

.label-layout-horizontal {
  & .field-title {
    flex: 0 0 33.333%;
    min-height: var(--form-row-height);
    margin-right: 8px;
  }
}

.label-layout-vertical {
  & .input-element {
    flex-direction: column;
  }

  & .field-title {
    margin-bottom: 8px;
  }
}

.field-title-colon,
.label-layout-colon .field-title:not(.field-title-no-colon) {
  & > .field-title-text::after {
    content: ':';
  }
}

.form-renderer-default-button {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.form-renderer-default-button-top {
  margin-bottom: 12px;
}

.form-renderer-default-button-bottom {
  margin-top: 12px;
}

.icp-audit-item {
  background: #f3f4f5;
  border-radius: var(--border-radius);
  margin-right: 12px;
  padding: 4px 8px;
  font-size: 0.9em;
  line-height: 1;

  & svg {
    margin: 0 4px;
  }
}

.icp-audit-field-name {
  color: #898b8c;
}

.icp-audit-content-tooltip {
  background: var(--ag-header-background-color);
  border-radius: 4px;
  border: 1px solid #bbb;
  padding: 6px;

  & .icp-audit-item {
    background: transparent;
    white-space: nowrap;
  }
}

.form-renderer-unknown-component {
  color: red;
}

/*
 * 增加了 material 过后，只对之前的 ant design 保持 input 类型的默认宽度和 margin 了，这个限制是最早的时候加的已经无法移除了。
 * 通过提供模板的方式来更加灵活的使用 input 类型组件
 */
.library-ant-design {
  & .input-element {
    width: 480px;
    max-width: 100%;
    min-height: var(--form-row-height);

    &:not(:last-child) {
      margin-bottom: 28px;
    }
  }

  & .editable-table-element,
  & .rich-text-element,
  & .code-editor-element,
  & .checkbox-element {
    width: 100%;
  }

  & .stack-layout {
    & > .input-element {
      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }

  & .grid-layout-item {
    & > .input-element {
      width: 100%;
    }

    & > .date-picker-element {
      & .ant-picker {
        width: 100%;
      }
    }

    & > .number-picker-element {
      & .ant-input-number {
        width: 100%;
      }
    }
  }
}
