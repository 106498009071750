.icp-table-filter-panel {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  min-height: 32px;
  gap: 6px;
  font-size: 14px;
}

.icp-table-filter-item {
  gap: 4px;
  box-sizing: border-box;
  height: 28px;
  padding: 0 11px;
  border-radius: 16px;
  background: white;
  border: 1px solid var(--ag-border-color);
  cursor: pointer;
  user-select: none;
  white-space: nowrap;

  & svg,
  & .icp-filter-type {
    color: rgb(0 0 0 / 50%);
  }

  & .icp-filter-type {
    font-size: 13px;
  }

  & .icp-filter-value {
    max-width: 180px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.icp-table-filter-add-button {
  height: 28px;
  border-radius: 16px;
  color: rgb(0 0 0 / 50%);

  & svg {
    color: rgb(0 0 0 / 50%);
  }
}

.icp-table-filter-delete-button {
  width: 20px;
  min-width: 20px;
  height: 20px;
  padding: 0;
  border-radius: 50%;
}

.icp-table-filter-item-detail {
  min-width: 200px;
  padding: 6px;

  & .ant-picker,
  & .ant-input-number input,
  & input {
    padding-top: 2.5px;
    padding-bottom: 2.5px;
  }

  & .ant-input-number,
  & .ant-picker,
  & input {
    min-width: 120px;
  }
}

.icp-table-filter-item-condition {
  justify-content: space-between;
  gap: 4px;

  & button {
    height: 24px;
    min-width: 24px;
    padding: 0 4px;
  }
}

.icp-table-filter-item-value {
  justify-content: flex-start;
  gap: 8px;
  margin-top: 6px;

  & > * {
    flex: auto;
  }
}
