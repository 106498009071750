.permissions-element {
  &.permissions-element {
    width: 100%;
  }

  & .perm-category-section {
    &:not(:last-child) {
      margin-bottom: 48px;
    }

    & .section-title {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
    }

    & .section-title > *:not(:last-child) {
      margin-right: 20px;
    }
  }
}
