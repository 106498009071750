.icp-tabs {
  display: flex;
  padding-top: 6px;
  padding-bottom: 5px;
  border-bottom: 1px solid rgb(0 0 0 / 8%);
}

.icp-tab {
  position: relative;
  height: 28px;
  border-radius: 4px;
  user-select: none;
  cursor: pointer;

  &.current {
    &::after {
      position: absolute;
      width: 80%;
      bottom: -6px;
      left: 50%;
      transform: translateX(-50%);
      content: '';
      border-bottom: 2px solid rgb(55 53 47);
    }
  }
}
