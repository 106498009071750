.icp-acl {
  position: relative;
  display: flex;
  align-items: center;
  gap: 8px;
}

.icp-acl-input.icp-acl-input.icp-acl-input {
  flex: 1;
}

.acl-table {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.icp-acl-dialog {
  &.icp-acl-dialog.multiple {
    & .ant-modal-footer {
      margin-top: 0;
    }
  }

  & .ant-modal-content {
    height: 800px;
  }
}

.icp-table-transfer {
  height: 100%;
  display: flex;
  gap: 8px;
}

.icp-transfer-list {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.icp-transfer-border {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.icp-transfer-operator {
  flex: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 8px;
}

.icp-transfer-count {
  height: 24px;
  font-weight: 500;
  margin-top: 12px;
}
